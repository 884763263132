<template>
  <div class="colaboradores">
    <h1 class="titulo-card fs-4">Colaboradores</h1>
    <div class="form-group card-principal">
      <div class="d-flex justify-content-between">
        <div class="input-group mb-3">
          <button v-on:click="handleAdicionar()" class="btn btn-success">
            <font-awesome-icon :icon="['fas', 'plus']" fixed-width class="me-1"/>Adicionar
          </button>
        </div>

        <div class="input-group mb-3">
          <input
              v-on:keyup.enter="handlePesquisa()"
              type="text"
              class="form-control"
              placeholder="Pesquisar"
              aria-label="Pesquisar"
              aria-describedby="button-addon2">
          <button v-on:click="handlePesquisa()" class="btn btn-outline-secondary" type="button" id="button-addon2">
            <font-awesome-icon :icon="['fas', 'search']" fixed-width class="me-1"/>
          </button>
        </div>
      </div>
      <div class="d-flex">
        <table class="table table-sm-pc table-striped table-hover">
          <thead>
            <tr>
              <th width="25">#</th>
              <th>Apelido</th>
              <th>Nome completo</th>
              <th width="110"></th>
            </tr>
            </thead>
            <tbody>
              <tr v-for="colaborador in listaColaboradores" :key="colaborador.id" v-bind:class="cssColaborador(colaborador)">
                <td scope="row">{{colaborador.id}}</td>
                <td>{{colaborador.apelido}}</td>
                <td>{{colaborador.nome_completo}}</td>
                <td class="text-end">
                  <button type="button" v-on:click="handleEditaColaborador(colaborador)" class="btn btn-primary btn-sm me-1">
                    <font-awesome-icon :icon="['fas', 'edit']" fixed-width/>
                  </button>
                  <button type="button" v-on:click="handleRemoveColaborador(colaborador)" class="btn btn-danger btn-sm">
                    <font-awesome-icon :icon="['fas', 'times']" fixed-width/>
                  </button>
                </td>

              </tr>
            </tbody>
        </table>
      </div>
      <Paginador v-model="paginador" @change-paginacao="onChangePaginacao"/>

      <!-- modal-edita-colaborador -->
      <!-- Button trigger modal -->
      <button type="button" class="d-none" data-bs-toggle="modal" data-bs-target="#modal-edita-colaborador" id="btn-modal-edita-colaborador-abre">
        Launch
      </button>

      <!-- Modal -->
      <div class="modal fade" id="modal-edita-colaborador" tabindex="-1" role="dialog" aria-labelledby="modelTitleId" aria-hidden="true">
        <div class="modal-dialog modal-xl modal-fullscreen-margin" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 v-if="(!modalColaborador.colaborador)" class="modal-title">
                <font-awesome-icon :icon="['fas', 'plus']" fixed-width/>
                Cadastrar novo colaborador
              </h5>
              <h5 v-if="(modalColaborador.colaborador)" class="modal-title">
                <font-awesome-icon :icon="['fas', 'pen']" fixed-width/>
                Editando colaborador
              </h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col-12">
                  <div class="form-check form-switch">
                    <input v-model="modalColaborador.form.ativo" class="form-check-input" type="checkbox" id="flexSwitchCheckDefault">
                    <label class="form-check-label" for="flexSwitchCheckDefault">Ativo</label>
                  </div>
                </div>
                <div class="col-12 col-sm-6">
                  <label class="required">Apelido</label>
                  <input type="text" v-model="modalColaborador.form.apelido" class="form-control">
                </div>
                <div class="col-12 col-sm-6">
                  <label class="required">Nome completo</label>
                  <input type="text" v-model="modalColaborador.form.nomeCompleto" class="form-control">
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal" id="btn-modal-edita-colaborador-fecha">Fechar</button>
              <button type="button"
                  v-if="(!modalColaborador.colaborador)"
                  v-on:click="handlemodalColaboradorCadastrar()"
                  v-bind:disabled="!podeSalvarColaborador()"
                  class="btn btn-success">
                Cadastrar
              </button>
              <button type="button"
                  v-if="(!!modalColaborador.colaborador)"
                  v-on:click="handlemodalColaboradorSalvar()"
                  v-bind:disabled="!podeSalvarColaborador()"
                  class="btn btn-success">
                Salvar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- modal-edita-colaborador -->
  </div>
</template>

<script>
import Paginador from '@/components/Paginador.vue'
import api from '@/modules/api'
import dialogo from '@/modules/dialogo'
import loading from '@/modules/loading'
import clone from '@/modules/clone'
import model from '@/model'

export default {
  name: 'Colaboradores',
  components: {
    Paginador,
  },
  data() {
    return {
      /** @type {model.paginador} */
      paginador: clone(model.paginador),
      listaColaboradores: [],
      modalColaborador: {
        colaborador: undefined,
        form: {
          ativo: true,
          apelido: '',
          nomeCompleto: '',
        },
      },
    }
  },
  methods: {
    handlePesquisa() {
      loading(true);

      api.get(`/colaboradores?${this.paginador.requestParams}`).then(res => {
        loading(false);
        this.listaColaboradores = res.data.items;
        this.paginador.pagina = res.data.page;
        this.paginador.totalItens = res.data.totalItems;
      }).catch(error => {
        loading(false);
        dialogo.erroHttp(error);
      });
    },

    onChangePaginacao() {
      this.handlePesquisa();
    },

    handleAdicionar() {
      this.resetModalColaborador();
      this.abreModalCadastroColaborador();
    },

    async handleEditaColaborador(colaborador) {
      this.resetModalColaborador();

      this.modalColaborador.colaborador = colaborador;
      this.modalColaborador.form.ativo = colaborador.ativo;
      this.modalColaborador.form.apelido = colaborador.apelido;
      this.modalColaborador.form.nomeCompleto = colaborador.nome_completo;

      this.abreModalCadastroColaborador();
    },

    handleRemoveColaborador(colaborador) {
      dialogo.confirmacao(`Confirma remover o usuário <b>${colaborador.nome}</b>?`).then(() => {
        loading(true);

        api.delete(`/colaboradores/${colaborador.id}`).then(() => {
          loading(false);

          this.handlePesquisa();
        }).catch(error => {
          loading(false);
          dialogo.erroHttp(error);
        });
      });
    },

    cssColaborador(colaborador) {
      if (!colaborador.ativo) {
        return ['text-muted'];
      }

      return ['text-info'];
    },

    //MODAL EDIT
    abreModalCadastroColaborador() {
      document.getElementById('btn-modal-edita-colaborador-abre').click();
    },

    resetModalColaborador() {
      this.modalColaborador.colaborador = undefined;
      this.modalColaborador.form.ativo = true;
      this.modalColaborador.form.apelido = '';
      this.modalColaborador.form.nomeCompleto = '';
    },

    podeSalvarColaborador() {
      switch (true) {
        case (this.modalColaborador.form.apelido.length < 3):
        case (this.modalColaborador.form.nomeCompleto.length < 7):
          return false;

        default:
          return true;
      }
    },

    handlemodalColaboradorCadastrar() {
      const form = this.modalColaborador.form;

      const sendData = {
        ativo: form.ativo,
        apelido: form.apelido,
        nome_completo: form.nomeCompleto,
      };

      loading(true);

      api.post('/colaboradores', sendData).then(() => {
        loading(false);
        document.getElementById('btn-modal-edita-colaborador-fecha').click();
        this.handlePesquisa();
      }).catch(error => {
        loading(false);
        dialogo.erroHttp(error);
      });
    },

    handlemodalColaboradorSalvar() {
      const id = this.modalColaborador.colaborador.id;
      const form = this.modalColaborador.form;

      const sendData = {
        ativo: form.ativo,
        apelido: form.apelido,
        nome_completo: form.nomeCompleto,
      };


      loading(true);
      api.put(`/colaboradores/${id}`, sendData).then(() => {
        loading(false);
        document.getElementById('btn-modal-edita-colaborador-fecha').click();
        this.handlePesquisa();
      }).catch(error => {
        loading(false);
        dialogo.erroHttp(error);
      });
    },
    //MODAL EDIT
  },

  created() {
    this.handlePesquisa();
  }
}
</script>
